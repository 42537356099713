@use "../../styles/abstracts/colors.scss" as *;

.logo {
  // transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    width: 150px;
    height: 100%;
  }
}

.item {
  width: 56%;
  overflow: visible;
  // stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.light {
  background-color: $white;
  color: $black;
}

.dark {
  background-color: $black;
  color: $gray000;
}
