@use "../../styles/abstracts/colors.scss" as *;
@use "../../styles/abstracts/mixins.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-top: 1rem;
    font-size: 1rem;
    @include sm {
      font-size: 0.65rem;
    }
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    margin-right: 0.3rem;
    padding-bottom: 0.1rem;
    transition: margin 0.3s ease-in-out;

    &:hover {
      margin-right: 0.8rem;
    }
  }
}
