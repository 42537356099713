@use "../../styles/abstracts/colors.scss" as *;
@use "../../styles/abstracts/mixins.scss" as *;

.root {
  transition: height 0.3s ease-in-out;
  .contentContainer {
    display: flex;
    position: relative;
    // overflow: hidden;

    .container {
      position: relative;
      z-index: 2;
      width: 100%;
      padding-top: 80px;
      min-height: 600px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
      @include xl {
        min-height: 550px;
      }
      @include lg {
        min-height: 500px;
      }
      @include md {
        min-height: 450px;
      }
      @include sm {
        min-height: 400px;
      }
      @include xs {
        min-height: 350px;
      }
      p {
        font-size: clamp(0.9rem, 5vw, 1.3rem);
        font-weight: 400;
        text-align: center;
      }

      div {
        font-size: clamp(1.9rem, 8vw, 4.5rem);
        font-weight: bold;
      }
    }
    .imageContainer {
      position: absolute;
      z-index: 1;
      width: 50%;
      height: 500px;
      left: 50%;
      top: 15%;
      @include md {
        height: 400px;
        left: 40%;
        width: 60%;
      }
      @include sm {
        height: 350px;
        left: 30%;
        width: 70%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.light {
  // background-color: $white;
  color: $black;
}

.dark {
  // background-color: $black;
  color: $gray000;
}
