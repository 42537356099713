@use "../../styles/abstracts/mixins.scss" as *;
@use "../../styles/abstracts/colors.scss" as *;

.light {
  background-color: $white;
  color: $black;
}

.dark {
  background-color: $black;
  color: $gray000;
}

.sidebar {
  display: none;
  // height: 100vh;
  // width: 100vw;

  @include md {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 70%;
    height: 100%;
    z-index: 200;
    transition: transform 0.3s ease-out;

    .navlinks {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      gap: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .logo {
    margin-bottom: 4rem;
  }
  .themeSwitch {
    margin-top: 4rem;
  }
}
.sidebarBackground {
  display: none;
  @include md {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 100;
  }
}
.open {
  transform: translateX(0);
}
.close {
  transform: translateX(-100%);
}
