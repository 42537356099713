.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  
  svg {
    width: 50%;
    height: 100%;
  }
 }
 .item {
   width: 56%;
   overflow: visible;
   stroke: #fff;
   stroke-width: 1;
   stroke-linejoin: round;
   stroke-linecap: round;
 }
