@use "../../styles/abstracts/colors.scss" as *;
@use "../../styles/abstracts/mixins.scss" as *;

.header {
  padding: 0 20px;
  height: 80px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.container {
  display: flex;
  justify-content: space-between;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .navlinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include md {
      display: none;
    }
  }
  .menuClose {
    display: none;
  }
  .themeSwitch {
    @include md {
      display: none;
    }
  }
  .toggleMenu {
    display: none;
    @include md {
      display: block;
    }
  }
}

.light {
  background-color: $white;
  color: $black;
}

.dark {
  background-color: $black;
  color: $gray000;
}
