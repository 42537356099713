@use "../../styles/abstracts/colors.scss" as *;
@use "../../styles/abstracts/mixins.scss" as *;

.footer {
  padding: 4rem 0;
  // transition: all 0.3s ease-in-out;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.footer_item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 3rem;
  &:last-child {
    margin-right: 0;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: clamp(1.7rem, 8vw, 3rem);
  }

  .data {
    font-size: clamp(0.7rem, 5vw, 1.1rem);
    margin-top: 3rem;
  }
}

.split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    text-transform: capitalize;
  }

  .social,
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 4rem;
    @include sm {
      margin: 0 1rem 0 0;
    }

    a {
      font-size: 1.1rem;
      margin: 0.5rem 0;
    }
  }
}
.social_link {
  display: inline-block;
  margin: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.1rem;
  text-transform: capitalize;

  &:after {
    content: "";
    display: block;
    border-bottom: solid 2px currentColor;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
  }

  &:hover::after {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }
}

.light {
  background-color: $white;
  color: $black;
}

.dark {
  background-color: $black;
  color: $gray000;
}
