@use "../../styles/abstracts/colors.scss" as *;

.link {
  display: inline-block;
  margin: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.1rem;
  text-transform: capitalize;

  &:after {
    content: "";
    display: block;
    border-bottom: solid 2px currentColor;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
  }

  &:hover::after {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }
}

.light {
  // background-color: $white;
  color: $black;
}

.dark {
  // background-color: $black;
  color: $gray000;
}
