

$red400: #FF8787;
$red500: #FF6B6B;
$red600: #FA5252;
$red700: #F03E3E;
$red800: #E03131;
$red900: #C92A2A;

$pink400: #F783AC;
$pink500: #F06595;
$pink600: #E64980;
$pink700: #D6336C;
$pink800: #C2255C;
$pink900: #A61E4D;

$grape400: #DA77F2;
$grape500: #CC5DE8;
$grape600: #BE4BDB;
$grape700: #AE3EC9;
$grape800: #9C36B5;
$grape900: #862E9C;

$violet400: #9775FA;
$violet500: #845EF7;
$violet600: #7950F2;
$violet700: #7048E8;
$violet800: #6741D9;
$violet900: #5F3DC4;

$indigo400: #748FFC;
$indigo500: #5C7CFA;
$indigo600: #4C6EF5;
$indigo700: #4263EB;
$indigo800: #3B5BDB;
$indigo900: #364FC7;

$blue400: #4DABF7;
$blue500: #339AF0;
$blue600: #228BE6;
$blue700: #1C7ED6;
$blue800: #1971C2;
$blue900: #1864AB;

$cyan400: #3BC9DB;
$cyan500: #22B8CF;
$cyan600: #15AABF;
$cyan700: #1098AD;
$cyan800: #0C8599;
$cyan900: #0B7285;

$teal400: #38D9A9;
$teal500: #20C997;
$teal600: #12B886;
$teal700: #0CA678;
$teal800: #099268;
$teal900: #087F5B;

$green400: #69DB7C;
$green500: #51CF66;
$green600: #40C057;
$green700: #37B24D;
$green800: #2F9E44;
$green900: #2B8A3E;

$lime400: #A9E34B;
$lime500: #94D82D;
$lime600: #82C91E;
$lime700: #74B816;
$lime800: #66A80F;
$lime900: #5C940D;

$yellow400: #FFD43B;
$yellow500: #FCC419;
$yellow600: #FAB005;
$yellow700: #F59F00;
$yellow800: #F08C00;
$yellow900: #E67700;

$orange400: #FFA94D;
$orange500: #FF922B;
$orange600: #FD7E14;
$orange700: #F76707;
$orange800: #E8590C;
$orange900: #D9480F;

$dark000: #C1C2C5;
$dark100: #A6A7AB;
$dark200: #909296;
$dark300: #5C5F66;
$dark400: #373A40;
$dark500: #2C2E33;
$dark600: #25262B;
$dark700: #1A1B1E;
$dark800: #141517;
$dark900: #101113;

$gray000: #F8F9FA;
$gray100: #F1F3F5;
$gray200: #E9ECEF;
$gray300: #DEE2E6;
$gray400: #CED4DA;
$gray500: #ADB5BD;
$gray600: #868E96;
$gray700: #495057;
$gray800: #343A40;
$gray900: #212529;


$white: #fff;
$black: #000;