@use "../../styles/abstracts/colors.scss" as *;
@use "../../styles/abstracts/mixins.scss" as *;

.root {
  margin-top: 80px;
  .imageContainer {
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .image {
      display: block;
      aspect-ratio: 4/1;
      @include md {
        aspect-ratio: 2/1;
      }
      @include sm {
        height: 300px;
        // aspect-ratio: 2/1;
      }
    }
  }
  .content {
    margin: 4rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 60px;
    @include md {
      flex-direction: column;
      gap: 0;
    }
    h3 {
      text-transform: capitalize;
      margin: 1rem 0;
      font-size: clump(1.5rem, 8vw, 2rem);
      opacity: 0.7;
    }
    p {
      margin: 1rem 0;
    }
    a {
      opacity: 0.6;
      margin: 1rem 0;
      text-decoration: underline;
    }
    .title {
      width: 40%;
      @include md {
        width: 90%;
      }
      h1 {
        font-size: clump(2.5rem, 8vw, 2rem);
        font-weight: 600;
      }
    }
  }
  .main {
    margin: 4rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

    @include md {
      // flex-direction: column;
      gap: 10px;
    }
    h3 {
      text-transform: capitalize;
      margin-top: 1rem;
      font-size: 1.5rem;
    }
    p {
      margin: 1rem 0;
    }
    .goal {
      width: 60%;
      @include md {
        width: 100%;
      }
    }
    .description {
      width: 70%;
      @include md {
        width: 100%;
      }
    }
  }
}

.light {
  background-color: $white;
  color: $black;
}

.dark {
  background-color: $black;
  color: $gray000;
}
