@use "../abstracts/colors.scss" as *;
@use "../abstracts/mixins.scss" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  overflow-anchor: none;
  scroll-behavior: smooth;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  width: 1280px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  @include md {
    padding: 0 10px;
  }
}
