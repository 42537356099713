.root {
  inset: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: absolute;
  z-index: 50;
  width: 100%;
}

.light {
  background-color: #000;
  color: #fff;
}

.dark {
  background-color: #fff;
  color: #000;
}
